import React from 'react'
import { TypeConfig } from '../types'
import { useConfig } from './hooks'
import ImageMenu from './ImageMenu'
import StaticPage from './StaticPage'
import Sequence from './Sequence'
import { useHistory } from 'react-router-dom'

const Home = React.memo(function Home(props: any) {
    const { root = {} as any, base } = useConfig()
    const { image_menu_id, target_id } = root
    const history = useHistory()

    const model = target_id ? target_id.split('-')[0] : 'image_menu'
    const id = target_id ? target_id.split('-')[1] : image_menu_id

    root?.sequence_group_id && history.push(`${base}${root.sequence_group_id}`)

    return (
        <>
            <main className={'flex flex-col justify-center items-center w-screen h-screen'}>
                {(model === 'static_page' && <StaticPage id={id} />) || null}
                {(model === 'image_menu' && <ImageMenu id={id} />) || null}
                {(model === 'sequence' && <Sequence sequence_id={id} />) || null}
            </main>
        </>
    )
})

export default Home
